<template>
	<v-row>
		<v-row justify="center" max-width="344">
			<v-col cols="12" sm="11">
				<v-form
					ref="form"
					v-model="isvalid"
					lazy-validation
					>
					<v-card>
						<v-card-title align="left" class="headline primary create-disb-title">
							<h5>Add Purchase Order</h5>
						</v-card-title>     
						<v-container grid-list-md class="create-disb-container">
							<v-layout>
								<v-flex><h3>Purchase Order Info</h3></v-flex>
								<h3>PO Date: {{getCurrentDate()}}</h3>
							</v-layout>
							<br>
							<v-layout>
								<v-flex xs12 sm12 md8>
									<v-checkbox
										v-model="partial_payment"
										label="Partial Payment?"
										>
									</v-checkbox>
								</v-flex>
								<v-flex xs12 sm6 md4>
									<v-text-field
										label="Down Payment"
										v-model="down_payment"
										type="number"
										v-if="partial_payment == 1"
										min="0"
										:rules="down_payment_rules"
									/>
								</v-flex>
							</v-layout>
							<v-layout>
								<v-flex xs12 sm12 md2>
									<v-checkbox
										v-model="delivered"
										label="Is Delivered?"
										>
									</v-checkbox>
								</v-flex>
							</v-layout>
							<br>
							<v-layout wrap>
								<v-flex xs12 sm12 md12>
									<h4>Mode of Payment</h4>
								</v-flex>
								<v-flex xs12 sm12 md12>
									<v-radio-group
										v-model="payment_mode"
									>
										<v-radio
										label="Head Office Disbursement"
										value="0"
										></v-radio>
										<v-radio
										label="Branch Disbursement through RFA"
										value="1"
										></v-radio>
									</v-radio-group>
								</v-flex>
							</v-layout>
							<v-layout>
								<!-- <v-flex xs12 sm12 md8>
									<p>Subject To VAT:</p>
									<v-radio-group v-model="subject_to_vat" :rules="vat_rules" row>
										<v-radio label="Yes" value="1"></v-radio>
										<v-radio label="No" value="0"></v-radio>
									</v-radio-group>
								</v-flex> -->
								<v-flex xs12 sm6 md12>
									<v-autocomplete
										:items="vatType"
										class="mt-6"
										editable
										v-model="vat_type"
										item-text="text"
										item-value="value"
										label="VAT Type"
										:rules="vat_type_rules"
										>
									</v-autocomplete>
								</v-flex>
							</v-layout>
							<v-layout wrap>
								<v-flex xs12 sm6 md4>
									<v-menu
										v-model="batch_datepicker"
										:nudge-right="40"
										transition="scale-transition"
										offset-y
										min-width="290px"
										:close-on-content-click="false"
										>
										<template v-slot:activator="{ on }">
											<v-text-field
												label="Shipping Date"
												v-mask="date_format"
												v-model="shipping_date"
												required
												readonly
												:rules="date_rules"
												return-masked-value
												v-on="on"
												mask="YYYY-MM-DD"
												placeholder="YYYY-MM-DD">
											</v-text-field>
										</template>
										<v-date-picker
											color="primary"
											v-model="shipping_date"
											@change="batch_datepicker=false"
											>
										</v-date-picker>
									</v-menu>
								</v-flex>
								<v-flex xs12 sm6 md4>
									<v-text-field
										label="Payment Term"
										v-model="paymentTerm"
										required
										:rules="payment_term_rules"
										>
									</v-text-field>
								</v-flex>
								<v-flex xs12 sm6 md4>
									<v-autocomplete
										:items="paymentType"
										editable
										v-model="payment_type"
										item-text="text"
										item-value="text"
										label="Payment Type"
										:rules="payment_type_rules"
										>
									</v-autocomplete>
								</v-flex>
								<v-flex xs12 sm6 md12>
									<v-autocomplete
										:items="filterPayee()"
										editable
										v-model="selected_payee"
										item-text="payee_desc"
										item-value="id"
										label="Vendor"
										v-on:change="payees_on_change(selected_payee.id)"
										return-object
										:rules="vendor_rules"
										>
									</v-autocomplete>
								</v-flex>
								<v-flex xs12 sm6 md4>
									<v-text-field
										label="Contact Person"
										v-model="contact_person"
										required
										:rules="contact_person_rules"
										>
									</v-text-field>
								</v-flex>
								<v-flex xs12 sm6 md4>
									<v-text-field
										label="Address"
										v-model="address"
										required
										readonly
										>
									</v-text-field>
								</v-flex>
								<v-flex xs12 sm6 md4>
									<v-text-field
										label="Business Style"
										v-model="business_style"
										required
										readonly
										>
									</v-text-field>
								</v-flex>
								<v-flex xs12 sm6 md4>
									<v-text-field
										label="TIN"
										v-model="tin"
										required
										readonly
										>
									</v-text-field>
								</v-flex>
								<v-flex xs12 sm6 md4>
									<v-text-field
										label="Contact Number"
										v-model="contact_number"
										required
										readonly
										>
									</v-text-field>
								</v-flex>
								<v-flex xs12 sm6 md4>
									<v-text-field
										label="Email Address"
										v-model="email_add"
										required
										readonly
										>
									</v-text-field>
								</v-flex>
								<v-flex xs12 sm6 md12>
									<v-textarea
										v-model="remarks"
										editable
										label="Remarks"
										rows="2"
										counter=300
										maxlength=300
										:rules="remarks_rules"
										>
									</v-textarea>
								</v-flex>
							</v-layout>
							<div class="container">
								<div class="large-12 medium-12 small-12 cell">
									<v-file-input
										v-model="upload_files"
										label="Attach Files"
										multiple
										prepend-icon="mdi-paperclip"
										:rules="attachment_rules"
										>
										<template v-slot:selection="{ text, index }">
											<v-chip
												small
												label
												color="primary"
												close
												@click:close="removeFile(index)"
												>
												{{ text }}
											</v-chip>
										</template>
									</v-file-input>
								</div>
							</div>
						</v-container>
						<v-divider class="create-disb-divider"></v-divider>
						<v-container class="doc-items-container" grid-list-md>
							<p class="caption"></p>
							<v-row justify="center">
								<v-expansion-panels focusable multiple tile outlined>
									<v-expansion-panel class="document-card-container" outlined expand>
										<v-expansion-panel-header tile outlined>
											<template>
												<v-card-title dark class="document-card-title">
													Purchase Order No. {{poNumber}}
													<v-spacer></v-spacer>
												</v-card-title>
											</template>
										</v-expansion-panel-header>
										<v-expansion-panel-content outlined>
											<template>
												<v-card tile outlined class="document-form-container">
													<trx-detail @changedPODetails="getChangedDetails" ref="createTrxDetail"></trx-detail>
												</v-card>
											</template>
										</v-expansion-panel-content>
									</v-expansion-panel>
								</v-expansion-panels>
							</v-row>
						</v-container>
						<v-divider class="create-disb-divider"></v-divider>
						<v-card-actions class="card-action-container">
							<div>
								<v-btn text color="blue accent-4" @click="createDialog = true">Create Purchase Order</v-btn>
								<v-btn dark color="red accent-4" @click="removeStorage('cancel')">Cancel</v-btn>
							</div>
						</v-card-actions>
					</v-card>
				</v-form>
			</v-col>
		</v-row>

		<!-- Dialog -->
		<div class="text-center">
			<v-dialog
				v-model="createDialog"
				persistent max-width="600"
				>
				<v-card>
					<v-card-title
						class="headline primary white--text"
						primary-title
						>
						Add Purchase Order
					</v-card-title>
					<br>
					<v-card-text>
						Are you sure you want to submit the form?
					</v-card-text>

					<v-divider></v-divider>

					<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn
							color="primary"
							text
							@click="save('create')"
						>
							Save
						</v-btn>
						<v-btn
							color="red"
							text
							@click="createDialog = false"
							>
							Cancel
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>
		</div>
		
		<!-- Snackbar -->
		<v-snackbar
			v-model="snackbar"
			right
			>
			{{ error_message }}
			<v-btn
				color="pink"
				text
				@click="snackbar = false"
				>
				Close
			</v-btn>
		</v-snackbar>
		<v-snackbar
			v-model="snackbarDetail"
			right
			>
			{{ snackbarDetailText }}
			<v-btn
				color="pink"
				text
				@click="snackbarDetail = false"
				>
				Close
			</v-btn>
		</v-snackbar>
	</v-row>
</template>
<script>
import trxDetail from './createPurchaseOrderDetail.vue';
import { mask } from 'vue-the-mask';
import { mapGetters } from 'vuex';
import { format } from 'date-fns';
import jsonToFormData from 'json-form-data';
import numeral from 'numeral';

export default {
	directives: {
		mask,
	},
	components: {
		'trx-detail': trxDetail,
	},
	data() {
		return {
			vatType: [
				{ value: '1', text: 'Vatable' },
				{ value: '2', text: 'Vat Exempt' },
				{ value: '3', text: 'Zero Rated' },
			],
			paymentType: [
				{ text: 'COD/Advance Payment' },
				{ text: 'Credit Term' }
			],
			down_payment: 0.00,
			partial_payment: false,
			contact_person: '',
			snackbarDetail: false,
			snackbarDetailText: '',
			subject_to_vat: 0,
			vat_type: '',
			payment_type: '',
			supplier_name: '',
			shipping_date: '',
			payee_desc: '',
			trader_name: '',
			address: '',
			delivered: false,
			business_style: '',
			tin: '',
			contact_number: '',
			email_add: '',
			selected_payee: null,
			paymentTerm: '',
			remarks: '',
			batch_datepicker: false,
			show: false,
			isvalid: false,
			date_format: '####-##-##',
			upload_files: [],
			upload_file: [],
			snackbar: false,
			error_message: 'Error: Please fill out the required fields',
			file: '',
			createDialog: false,
			createDisbDialog: false,
			payment_mode: '0',
			poItems: [
				{
					po_number: this.po_number,
					po_date: this.getCurrentDate(),
					item_description: '',
					quantity: 0,
					unit_cost: numeral(0).format('0,0.00'), 
					total_amount: numeral(0).format('0,0.00')
				}
			],
			vat_rules:  [
				v => !!v || 'Subject to VAT is required',
			],
			date_rules: [
				v => !!v || 'Shipping Date is required',
			],
			payee_rules: [
				v => !!v || 'Payee is required',
			],
			payment_term_rules: [
				v => !!v || 'Payment Term is required',
			],
			payment_type_rules: [
				v => !!v || 'Payment Type is required',
			],
			vendor_rules: [
				v => !!v || 'Vendor is required',
			],
			attachment_rules: [
				v => !!v || 'Attachments are required',
			],
			remarks_rules: [
				v => !!v || 'Remarks are required',
				(v) =>
					(v && v.length <= 300) ||
					'Remarks must be less than 300 characters',
			],
			vat_type_rules:  [
				v => !!v || 'VAT Type is required',
			],
			contact_person_rules:  [
				v => !!v || 'Contact Person is required',
			],
			down_payment_rules: [
				v => !!v || 'Down Payment is required',
			]
		};
	},
	computed: {
		...mapGetters({
			banks: 'bank/banks',
			currUser: 'auth/currUser',
			success: 'trxdisb/success',
			dist_data: 'trxdisb/dist_data',
			depts: 'dept/depts',
			payeefile: 'payefile/payefiles',
			acctperiodmains: 'acctperiodmain/acctperiodmains',
			currency: 'currency/currencies',
			regulartrx: 'regularTxns/regulartxns',
			approvinglevel: 'approvinglevel/approvinglevels',
			coas: 'coa/coaSubs',
			coaData: 'coa/coaData',
			poNumber: 'trxpurchaseorder/PONumber',
			isLoading: 'app/isLoading',
		}),
		payees(){
			let data = this.payeefile.filter(e => e.status === 1);
			return data;
		}
	},
	async mounted() {
		this.payment_mode = '0';
		this.$store.dispatch('payefile/getPayefiles');
		if(this.poNumber === '') {
			const PONumber = await this.$store.dispatch('trxpurchaseorder/getPONumber', {edit_by: this.currUser.id});
			this.$store.commit('trxpurchaseorder/SET_PO_NUMBER', PONumber.data.data.po_number);
		}
	},
	methods: {
		getCurrentDate() {
			return format(new Date(), 'yyyy-MM-dd');
		},
		removeStorage () {
			this.$router.push('/trx-purchase-order');
		},
		getChangedDetails (emitted) {
			this.poItems = emitted;
		},
		removeFile (index) {
			this.upload_files.splice(index, 1);
		},
		filterPayee () {
			var filtered = this.payees.filter(e => {
				if(e.paye_type === 'SUPPLIER'){
					return e;
				}
			});
			return filtered;
		},
		async payees_on_change(value){
			const res = await this.$store.dispatch('trxpurchaseorder/getPayeeFile', value);
			this.tin = res.data.data[0].tin;
			this.supplier_name = res.data.data[0].payee_desc;
			this.trader_name = res.data.data[0].trader_name;
			this.business_style = res.data.data[0].business_style;
			this.contact_number = res.data.data[0].contact_num;
			this.email_add = res.data.data[0].email_add;
			this.address = res.data.data[0].house_unit + ' ' + res.data.data[0].street + ' ' + res.data.data[0].barangay + ', ' +  res.data.data[0].city + ' ' + res.data.data[0].zip_code;
		},
		getCurrentDat () {
			return format(new Date(), 'yyyy-MM-dd');
		},
		async save () {
			this.isvalid = this.$refs.form.validate();
			var detailErrorArr = [];
			// if(this.poItems.length <= 1){
			// 	detailErrorArr.push({
			// 		title: 'Error!',
			// 		text: 'Detail should include at least 2 items',
			// 		type: 'error'
			// 	});
			// }
			if(this.upload_files.length == 0){
				detailErrorArr.push({
					title: 'Error!',
					text: 'File Attachment is required!',
					type: 'error'
				});
			}
			this.poItems.forEach(function(e, index){
				var number = index+=1;
				if(e.item_description === '') {
					detailErrorArr.push({
						title: 'Error!',
						text: 'Item Description is required at item number ' + number,
						type: 'error',
					});
				}  
				if (e.quantity === '' || e.quantity === 0) {
					detailErrorArr.push({
						title: 'Error!',
						text: 'Quantity is required at item number ' + number,
						type: 'error',
					});
				}  
				if (e.unit_cost === '' || e.unit_cost === '0.00'){
					detailErrorArr.push({
						title: 'Error!',
						text: 'Unit Cost is required at item number ' + number,
						type: 'error',
					});
				}
			});
			if (this.isvalid && detailErrorArr.length === 0) {
				var options = {
					initialFormData: new FormData(),
					showLeafArrayIndexes: true,
					includeNullValues: false,
					mapping: function(value) {
						if (typeof value === 'boolean') {
							return +value ? '1': '0';
						}
						return value;
					}
				};
				let data = {};
				let detail = [];
				let header = [];
				let amounts = [];
				// let total_amount = 0;
				const self = this;
				// let total_amount = '';
				this.poItems.forEach(function(e){
					amounts.push(parseFloat(e.total_amount));
					// total_amount = total_amount + parseFloat(e.total_amount);
					e.po_number = self.poNumber;
					e.po_date = self.getCurrentDate();
					e.edit_date = self.getCurrentDate();
					e.edit_by = self.currUser.id;
					e.status = '1';
				});

				let total_amount = amounts.reduce((a, b) => a + b, 0);
				data = {
					image_title: [],
					upload_file: []
				};

				for( var k = 0; k < this.upload_files.length; k++ ){
					let file = this.upload_files[k];
					data.image_title.push(file.name);
					data.upload_file.push(file);
				}

				header = [
					{
						po_number: this.poNumber,
						po_date: this.getCurrentDate(),
						payee_id: this.selected_payee.id,
						payment_term: this.paymentTerm,
						shipping_date: this.shipping_date,
						subject_to_vat: this.vat_type == 1 ? 1 : 0,
						vat_type: this.vat_type,
						delivered_flag: this.delivered,
						payment_type: this.payment_type,
						amount: total_amount.toFixed(2),
						remarks: this.remarks,
						status: '1',
						cancl_flag: '0',
						edit_by: this.currUser.id,
						edit_date: this.getCurrentDate(),
						contact_person: this.contact_person,
						is_partial_payment: this.partial_payment,
						down_payment: this.down_payment,
						payment_mode: this.payment_mode
					}
				];

				detail = this.poItems;
				data.headers = header;
				data.details = detail;
				var convertedFormData = jsonToFormData(data, options);
				this.$store.commit('app/SET_IS_LOADING', true, { root: true });
				const res = await this.$store.dispatch('trxpurchaseorder/submitPO', convertedFormData);
				this.$store.commit('app/SET_IS_LOADING', false, { root: true });
				if(res.status == 200){
					this.$swal.fire({
						type: 'success',
						title: 'PO Saved Successfully',
						timer: 3000
					}).then(() => {
						window.location.href = '/trx-purchase-order';
					});
					let routeData = this.$router.resolve({path: '/purchase-order-print/'+self.poNumber, data: self.poNumber});
					window.open(routeData.href, '_blank');
					this.$store.commit('trxpurchaseorder/UNSET_PO_NUMBER');
				}
			} else {
				this.snackbar = true;
				this.createDialog = false;
				const self = this;
				self.$swal.queue(detailErrorArr);
			}
		}
	}
};
</script>
<style scoped>
	.create-disb-title {
		color: #FFFFFF;
	}

	.card-action-container {
		display: flex;
		flex-direction: row-reverse;
	}

	.v-card .v-divider.create-disb-divider {
		margin-left: 0 !important;
		margin-right: 0 !important;
	}
</style>
